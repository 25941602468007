<template>
  <div>
    <loading :active.sync="loading"></loading>
    <h3>รับออเดอร์หลังบ้าน</h3>
    <v-form ref="form1" :lazy-validation="lazy">
      <v-row>
        <v-col cols="12" md="2">
          <p>หมายเลขโทรศัพท์*</p>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="telNumber"
            v-mask="'##########'"
            solo
            dense
            :rules="Rules.required"
            counter="10"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn @click="login()">ตรวจสอบข้อมูล</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="customerId">
      <v-form ref="form2" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="2">
            <p>ชื่อ*</p>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="firstName" solo dense> </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <p>นามสกุล*</p>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="lastName" solo dense> </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <p>อีเมล</p>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="email" solo dense> </v-text-field>
          </v-col>
        </v-row>

        <v-card outlined class="pa-4">
          <v-row v-for="(n, index) in productPriceList" :key="index">
            <v-col cols="12" md="2">
              <p>หมายเลขลอตเตอรี่*</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="productPriceList[index]"
                :items="itemsLotteries"
                item-text="lotteryNo"
                placeholder="เลือกหมายเลขลอตเตอรี่"
                solo
                dense
                @change="SumPrice()"
                return-object
                :rules="Rules.required"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" v-if="productPriceList[index]">
              <p>
                จำนวน Stock {{ productPriceList[index].setAmt }} ใบ<br />
                <!-- ชุด {{ productPriceList[index].setAmt }} ใบ -->
              </p>
            </v-col>
            <v-col cols="12" md="2" v-else>
              <!-- <p>จำนวน Stock {{productPriceList[index].stock}}</p>  -->
            </v-col>
            <v-col cols="12" md="4" class="my-2">
              <v-row align="center" justify="end">
                <v-btn
                  v-if="productPriceList.length > 1"
                  @click="deleteRow1()"
                  dense
                  color="red"
                  class="mr-4"
                  dark
                  rounded
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn @click="addRow1()" dense color="green" dark rounded>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <p>ราคารวม*</p>
            </v-col>
            <v-col cols="12" md="4">
              <span>{{ totalPrice }}</span>
            </v-col>
          </v-row>
        </v-card>

        <v-row align="center" justify="center" class="mt-1">
          <v-col cols="12" md="12" sm="6" xs="12">
            <v-card outlined class="pa-4">
              <v-row no-gutters align="center" justify="center">
                <v-col cols="12" class="pt-6">
                  <span>อัพโหลดรูปสลิปที่นี่</span>
                  <span style="color: red">*</span>
                </v-col>
                <!-- <v-spacer></v-spacer> -->
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <v-row align="center" justify="center">
                    <v-img
                      v-model="picture_1"
                      @click="changePic1()"
                      src="@/assets/upload.png"
                      class="mr-3"
                      max-width="30"
                    >
                    </v-img>
                    <span>เลือกรูปภาพ</span>
                  </v-row>
                  <v-row align="center" justify="center" class="mt-4">
                    <v-col
                      cols="5"
                      md="5"
                      class="pl-5 pr-5 pb-5 row fill-height sortable-list align-center"
                    >
                      <v-card dense light class="pa-1" v-if="showImage">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-icon small light @click="RemoveImage()"
                            >mdi-close</v-icon
                          >
                        </v-card-actions>

                        <v-img
                          :src="showImage"
                          width="200px"
                          aspect-ratio="0.6"
                          contain
                        ></v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                  <input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                    hidden
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-row class="ma-4" align="center" justify="end">
      <v-col cols="12">
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-btn class="mx-4" @click="submit()" color="primary" v-if="customerId"
          >บันทึก</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { Decode } from "@/services";
import { Encode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      lazy: false,
      customerId: "",
      telNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      pic1: [],
      picture_1: "",
      showImage: "",
      imageName1: "",
      totalPrice: 0,
      productPriceList: [],
      itemsLotteries: [],
      lotteriesData: [],
      feePrice: 0,
      status: "",
      Rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูลให้ถูกต้อง"],
      },
      totalAmt: 0,
    };
  },
  created() {
    this.lotteries = [];
    this.getLottery();
    this.addRow1();
    console.log(this.productPriceList);
  },
  methods: {
    async getLottery() {
      const responseConfigIsOpen = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN`,
        {}
      );
      console.log("responseConfigIsOpen", responseConfigIsOpen.data.data);
      if (responseConfigIsOpen.data.data.value1 == 1) {
        this.selling = true;
      } else {
        this.selling = false;
      }

      const data = {
        position1: "",
        position2: "",
        position3: "",
        position4: "",
        position5: "",
        position6: "",
        stock: 1,
        setAmt: [],
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/lottery/searchByNumber`,
        data
      );
      console.log("response", response.data.data);

      this.itemsLotteries = response.data.data;

      const responseConfig = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/OPERATION_PRICE`,
        {}
      );
      console.log("responseConfig", responseConfig.data.data);
      this.feePrice = responseConfig.data.data.value1 * 1;
      // this.lotteries.forEach((lot) => {
      //   lot.urlImage = lot.urlImage.replace("20210425", "20210424");
      //   // console.log(lot.urlImage);
      // });
    },
    SumPrice() {
      this.totalPrice = 0;
      this.totalAmt = 0;
      console.log(this.productPriceList);
      this.productPriceList.forEach((selectedLot) => {
        this.totalPrice =
          this.totalPrice +
          (80 + this.feePrice) * selectedLot.stock * selectedLot.setAmt;
        this.totalAmt = this.totalAmt + selectedLot.stock;
      });
    },
    addRow1() {
      this.productPriceList.push(null);
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
      this.SumPrice();
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    RemoveImage(index, item) {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage = "";
      this.imageName1 = "";
    },
    cancel() {
      this.$router.push("ManageOrder");
    },
    submit() {
      if (this.$refs.form2.validate(true)) {
        //   console.log("productPriceList", this.productPriceList);
        // if (!this.customerId) {
        //   this.$swal.fire({
        //     icon: "error",
        //     title: "กรุณากดตรวจสอบข้อมูล",
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        // }

        if (!this.pic1 || this.pic1.length == 0) {
          this.status = "BOOKING";
          this.pic1 = "";
          //   this.$swal.fire({
          //     icon: "error",
          //     title: "กรุณาอัพรูปหลักฐานการโอนเงิน",
          //     showConfirmButton: false,
          //     timer: 1500,
          //   });
        } else if (this.pic1) {
          this.status = "PENDING";
        }
        this.beforeTransaction();
      }
    },

    async beforeTransaction() {
      this.loading = true;
      //   this.user = JSON.parse(
      //     Decode.decode(localStorage.getItem("DDLotAdmin"))
      //   );
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      const userData = {
        firstName: this.firstName,
        lastName: this.lastName,
        telNumber: this.telNumber,
        email: this.email,
      };
      console.log("ส่งไป", userData, auth);
      const responseUser = await this.axios.put(
        `${process.env.VUE_APP_API}/users/` + this.customerId,
        userData,
        auth
      );
      console.log("ข้อมูลuser", responseUser);
      //   console.log("carts", carts);
      this.productPriceList.forEach((cart) => {
        const data = {
          lotteryNo: cart.lotteryNo,
          lotteryId: cart.id,
        };
        this.lotteriesData.push(data);
      });
      console.log("ตะกร้าก่อนส่ง", this.lotteriesData);
      // userId":9,"lotteries":[{"lotteryNo":352497,"lotteryId":141},{"lotteryNo":352497,"lotteryId":224}],"slipImg":""}
      const data = {
        totalPrice: this.totalPrice,
        isDelivery: "0",
        deliveryAddress: {
          address: "",
          subDistrict: "",
          district: "",
          province: "",
          postcode: "",
        },
        status: this.status,
        deliveryStatus: "WAITING",
        // userId: user.user.id,
        userId: this.customerId,
        lotteries: this.lotteriesData,
        slipImg: this.pic1,
        amount: this.totalAmt,
      };
      console.log("dataที่ส่งไปbefore", data);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/transaction/adminCreate`,
        data,
        auth
      );
      if (response.data.response_status == "SUCCESS") {
        this.loading = false;
        await this.$swal.fire({
          icon: "success",
          title: "ออเดอร์สำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
        await location.reload();
      }
      console.log("สำเร็จbefore", response.data);
      // this.trasactionId = response.data.data.id;
      if (response.data.response_status == "ERROR") {
        this.loading = false;
        this.$swal.fire({
          icon: "warning",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async login() {
      if (this.$refs.form1.validate(true)) {
        this.loading = true;
        this.customerId = "";
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        const data = {
          socialId: "",
          telNumber: this.telNumber.replaceAll("-", ""),
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/users/login`,
          data
        );
        console.log("response", response);
        if (response.data.response_status === "SUCCESS") {
          this.loading = false;
          const data = {
            token: response.data.data.token,
            user: response.data.data.user,
          };
          this.$swal.fire({
            icon: "success",
            title: "ดึงข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          var userData = response.data.data;
          if (userData.user) {
            if (userData.user.id) {
              this.customerId = userData.user.id;
            } else if (userData.id) {
              this.customerId = userData.id;
            }
            if (userData.user.firstName) {
              this.firstName = userData.user.firstName;
            }
            if (userData.user.lastName) {
              this.lastName = userData.user.lastName;
            }
            if (userData.user.email) {
              this.email = userData.user.email;
            }
          } else {
            if (userData.id) {
              this.customerId = userData.id;
            }
            if (userData.firstName) {
              this.firstName = userData.firstName;
            }
            if (userData.lastName) {
              this.lastName = userData.lastName;
            }
            if (userData.email) {
              this.email = userData.email;
            }
          }

          // localStorage.setItem("ReadyLotUser", encode(data));
          // window.location.reload();
        } else {
          this.loading = false;
          this.$swal.fire({
            icon: "warning",
            title: "ไม่มีข้อมูลผู้ใช้งาน โปรดติดต่อเจ้าหน้าที่",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
